<template>
  <div>
    <admin-search />
  </div>
</template>

<script>
import AdminSearch from "../../components/admin/search.vue";
export default {
  components: { AdminSearch },
};
</script>

<style lang="scss" scoped></style>

const firebaseInstance = require("../firebaseConfig.js");

const state = {
  uid: null,
  displayName: "",
  isLoggedIn: false,
  isAdmin: false
};
const mutations = {
  SET_USER(state, payload) {
    state.currentUser = payload;
    state.displayName = payload.displayName;
    state.uid = payload.uid;
    state.isAdmin = payload.role === "administrator";
  },
  CLEAR_USER(state) {
    state.currentUser = null;
    state.uid = null;
    state.displayName = "";
    state.uid = "";
    state.isAdmin = false;
  },
  SET_UID(state, uid) {
    state.uid = uid;
  },
  SET_LOGGED_IN_STATE(state, payload) {
    state.isLoggedIn = payload;
  }
};
const actions = {
  authAction({ commit }) {
    firebaseInstance.auth.onAuthStateChanged((user) => {
      if (user) {
        commit("SET_NOTHING", user);
      } else {
        commit("CLEAR_USER");
      }
    });
  },
  setUser({ commit }, payload) {
    commit("SET_USER", payload);
  },
  async getCurrentUser({ commit }) {
    try {
      let currentUser = firebaseInstance.firebase.auth().currentUser;
      commit("SET_USER", currentUser.user);
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  // eslint-disable-next-line
  async getUserProfile({ commit }, payload) {
    let uid = firebaseInstance.firebase.auth().currentUser.uid;
    if (payload) {
      uid = payload;
    }
    try {
      return Promise.resolve(uid);
    } catch (error) {
      console.log("Profile error.", error.message);
      return Promise.resolve();
    }
  },
  async signInAction({ commit }, payload) {
    try {
      await firebaseInstance.firebase
        .auth()
        .setPersistence(firebaseInstance.firebase.auth.Auth.Persistence.LOCAL);

      let currentUser = await firebaseInstance.auth.signInWithEmailAndPassword(
        payload.email,
        payload.password
      );
      commit("SET_USER", currentUser.user);
      return Promise.resolve();
    } catch (error) {
      console.log("error");
      console.error("signIn error:", error);
      return Promise.reject(error);
    }
  },
  async signOutAction({ commit }) {
    firebaseInstance.auth
      .signOut()
      .then(() => {
        commit("CLEAR_USER");
        return Promise.resolve();
      })
      .catch((error) => {
        commit("setError", error.message);
        return Promise.reject();
      });
  },

  async forgotPassword({ commit }, payload) {
    firebaseInstance.firebase
      .auth()
      .sendPasswordResetEmail(payload)
      .then(() => {
        commit("DO_NOTHING");
        return Promise.resolve();
      })
      .catch((err) => {
        console.log("err", err);
        return Promise.reject();
      });
  },
  setLoggedInStatus({ commit }, payload) {
    commit("SET_LOGGED_IN_STATE", payload);
  }
};

const getters = {
  uid(state) {
    return state.uid || "FALSE";
  },
  displayName(state) {
    return state.displayName || "";
  },
  getUser(state) {
    return state.data;
  },
  getLoggedInStatus(state) {
    return state.isLoggedIn;
  },
  getAdminStatus() {
    return state.isAdmin;
  }
};

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

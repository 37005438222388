<template>
  <b-table :data="reservations">
    <template #empty>
      <div class="has-text-centered">No reservations found.</div>
    </template>
    <b-table-column field="dateArrivalUnix" label="Arrival" v-slot="props">
      {{ props.row.dateArrivalUnix | moment("MM/DD/YYYY") }}
    </b-table-column>
    <b-table-column field="dateDepartureUnix" label="Departure" v-slot="props">
      {{ props.row.dateDepartureUnix | moment("MM/DD/YYYY") }}
    </b-table-column>
    <b-table-column v-if="showAddress" label="Address" v-slot="props">
      <property-address :propertyId="props.row.propertyId" />
    </b-table-column>
    <b-table-column field="primaryGuest" label="Primary Guest" v-slot="props">
      <span v-if="props.row.primaryGuest">
        {{ props.row.primaryGuest.nameFirst }}
        {{ props.row.primaryGuest.nameLast }}</span
      >
      <span v-else>&nbsp;</span>
    </b-table-column>
    <!-- <b-table-column v-slot="props" field="status" label="Status">
      {{ props.row.status }}
    </b-table-column> -->
    <b-table-column field="code" label="Code" v-slot="props">
      {{ props.row.code }}</b-table-column
    >

    <b-table-column v-slot="props">
      <div class="buttons">
        <router-link
          class="button is-small"
          :to="{ name: 'ViewReservation', params: { id: props.row.id } }"
          >View</router-link
        >
        <!-- <a class="button is-small" @click="handleSendPass(props.row.id)">
          Send Pass
        </a>
        <router-link
          class="button is-small"
          :to="{
            name: 'EditReservation',
            params: { id: props.row.id }
          }"
          >Edit</router-link
        >
        <a
          @click="handleRemoveReservation(props.row.id)"
          class="button is-small"
        >
          Remove
        </a> -->
      </div>
    </b-table-column>
  </b-table>
</template>

<script>
import propertyAddress from "../property/address.vue";

import { mapActions } from "vuex";
export default {
  props: ["reservations", "showAddress"],
  components: { propertyAddress },

  methods: {
    ...mapActions({
      removeReservation: "reservation/removeItem",
      sendPass: "reservation/sendPass"
    }),
    async handleSendPass(id) {
      await this.sendPass({ type: "reservations", typeId: id });
      alert("Pass Sent");
    },
    async handleRemoveReservation(id) {
      await this.removeReservation(id);
      alert("Reservation Removed");
    }
  }
};
</script>

<style lang="scss" scoped></style>

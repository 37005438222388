<template>
  <nav
    class="navbar is-dark is-fixed-top"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <div class="navbar-item">
        <h1 class="is-size-4 has-text-weight-bold">
        Kitomer
          <span class="has-text-grey-light">Admin</span>
        </h1>
      </div>
    </div>
    <div class="navbar-menu">
      <div class="navbar-end" v-if="isLoggedIn">
        <router-link class="navbar-item" :to="{ name: 'Admin' }"
          >Search</router-link
        >
        <!-- <router-link class="navbar-item" :to="{ name: 'Properties' }"
          >Properties</router-link
        > -->
        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link"> Reservations </a>

          <div class="navbar-dropdown">
            <router-link
              class="navbar-item"
              :to="{ name: 'ViewReservations', params: { type: 'current' } }"
              >Current</router-link
            >
            <router-link
              class="navbar-item"
              :to="{
                name: 'ViewReservations',
                params: { type: 'registered' }
              }"
              >Registered</router-link
            >
            <router-link
              class="navbar-item"
              :to="{ name: 'ViewReservations', params: { type: 'pending' } }"
              >Pending</router-link
            >
            <router-link
              class="navbar-item"
              :to="{ name: 'ViewReservations', params: { type: 'canceled' } }"
              >Canceled</router-link
            >
            <hr class="navbar-divider" />
            <router-link class="navbar-item" :to="{ name: 'AddReservation' }">
              Add Reservation
            </router-link>
          </div>
        </div>
        <a
          v-if="isLoggedIn"
          class="navbar-item navbar-end"
          @click="handleLogOut"
        >
          Log Out
        </a>
      </div>
    </div>
  </nav>
</template>

<script>
import { firebase } from "../firebaseConfig";
import { mapState, mapActions } from "vuex";
export default {
  name: "NavBar",
  computed: {
    ...mapState({
      isLoggedIn: (state) => state.user.isLoggedIn,
      properties: (state) => state.property.collection
    })
  },
  methods: {
    ...mapActions({
      logout: "user/signOutAction"
    }),
    async handleLogOut() {
      await this.logout();
      this.$router.push({ path: "/" });
    },
    sendEmail() {
      firebase
        .firestore()
        .collection("email")
        .add({
          to: "jesse@ossium.co",
          subject: "Hello from Firebase!",
          text: "This is the plaintext section of the email body.",
          html: "This is the <code>HTML</code> section of the email body."
        })
        .then(() => console.log("Queued email for delivery!"));
    }
  }
};
</script>
<style lang="scss" scoped>
.is-active {
  background-color: green !important;
}

.navbar-link:not(.is-arrowless)::after {
  right: 0.2em;
}
</style>

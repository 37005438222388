<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Reservations</li>
              <li>Add Reservation</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card">
      <!-- <div class="card-header">
        <div class="card-header-title is-uppercase">Add Reservation</div>
      </div> -->
      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="field">
            <label for="" class="label">Property</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select
                  v-model="reservation.propertyId"
                  v-if="properties.length > 0"
                >
                  <option
                    :value="property.id"
                    v-for="property in properties"
                    :key="property.id"
                  >
                    {{ property.houseNumber }} {{ property.streetName }}
                  </option>
                  <!-- properties -->
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label for="" class="label">Arrival Date and Time</label>

            <b-datetimepicker
              v-model="reservation.dateArrival"
              placeholder="Click to select..."
              icon-pack="fas"
              icon="calendar"
              horizontal-time-picker
              :timepicker="timepicker"
            >
            </b-datetimepicker>
          </div>
          <div class="field">
            <label for="" class="label">Departure Date and Time</label>

            <b-datetimepicker
              v-model="reservation.dateDeparture"
              placeholder="Click to select..."
              icon-pack="fas"
              icon="calendar"
              :min-datetime="reservation.dateArrival"
              horizontal-time-picker
              :timepicker="timepicker"
            >
            </b-datetimepicker>
          </div>
          <div class="mt-5">
            <button
              class="button is-large is-success is-fullwidth"
              type="submit"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
export default {
  data() {
    return {
      reservation: {
        dateArrival: new Date(),
        dateDeparture: new Date()
      },
      timepicker: {
        incrementMinutes: 15,
      }
    };
  },
  // watch: {
  //   "reservation.dateArrival": {
  //     // This will let Vue know to look inside the array
  //     deep: true,

  //     // We have to move our method to a handler field
  //     handler(newDate) {
  //       this.reservation.dateDeparture = newDate;
  //     }
  //   }
  // },
  methods: {
    ...mapActions({
      getProperties: "property/getCollection",
      createReservation: "reservation/createItem",
      success: "alert/success"
    }),
    async handleSubmit() {
      let newReservation = await this.createReservation(this.reservation);
      this.success("Created reservation. Please make first guest.");
      this.$router.push({
        name: "AddGuest",
        params: {
          propertyId: this.reservation.propertyId,
          reservationId: newReservation.id
        }
      });
    }
  },
  computed: {
    ...mapState({
      properties: (state) => state.property.collection
    })
  },
  async mounted() {
    if (this.properties.length === 0) {
      await this.getProperties();
    }
    if (this.$route.params.propertyId) {
      this.reservation.propertyId = this.$route.params.propertyId;
    }
    if (this.properties.length === 1) {
      this.reservation.propertyId = this.properties[0].id;
    }
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Reservation</li>
              <li>View</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)"> Back </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card">
      <div class="card-header is-block">
        <div class="columns">
          <div class="column">
            <div class="card-header-title is-size-5">
              <router-link
                :to="{ name: 'ViewProperty', params: { id: property.id } }"
              >
                {{ property.houseNumber }}
                {{ property.streetName }}</router-link
              >
            </div>
          </div>
          <div class="column has-text-right mr-4">
            <div class="card-header-title is-block is-size-5">
              {{ reservation.dateArrivalUnix | moment("MM/DD/YYYY") }} to
              {{ reservation.dateDepartureUnix | moment("MM/DD/YYYY") }}
            </div>
          </div>
        </div>
      </div>
      <div class="card-content">
        <div class="columns">
          <div class="column">
            <div>Status: {{ status }}</div>
            <div>
              Code:
              <span class="is-capitalized">{{ reservation.code }}</span>
            </div>
            <div v-if="reservation.registered">
              Registered:
              <span>{{
                reservation.registered | moment("ddd MMM Do YYYY @ h:mm a")
              }}</span>
            </div>
            <div>
              Paid: <span class="is-capitalized">{{ !!reservation.paid }}</span>
            </div>
            <div v-if="reservation.paymentAmount">
              Payment Amount: {{ (reservation.paymentAmount / 100) | currency }}
            </div>
          </div>
          <div class="column">
            <h5 v-if="reservation.dateArrivalUnix">
              Arrives:
              {{
                reservation.dateArrivalUnix
                  | moment("ddd, MMM Do YYYY @ h:mm a")
              }}
            </h5>
            <h5 v-if="reservation.dateDepartureUnix">
              Departs:
              {{
                reservation.dateDepartureUnix
                  | moment("ddd, MMM Do YYYY @ h:mm a")
              }}
            </h5>
          </div>
        </div>
      </div>
      <div class="card-footer">
        <router-link
          v-if="!reservation.canceled"
          class="card-footer-item"
          :to="{ name: 'EditReservation', params: { id: reservation.id } }"
          >Edit Reservation</router-link
        >
        <a
          v-if="!reservation.canceled"
          @click="handleCancelReservation"
          class="card-footer-item"
          >Cancel Reservation</a
        >
        <a
          v-if="reservation.canceled"
          @click="handleRestoreReservation"
          class="card-footer-item"
          >Restore Reservation</a
        >
        <a
          v-if="reservation.canceled"
          @click="handleDeleteReservation"
          class="card-footer-item"
          >Delete Reservation</a
        >
      </div>
    </div>
    <div class="mt-5" v-if="vehicles">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Vehicles</div>
          <button class="button is-small">Add Vehicle</button>
        </div>
        <div class="card-content">
          <div
            v-for="(vehicle, index) in vehicles"
            :key="index"
            class="tag is-large is-dark py-5"
          >
            {{ vehicle }}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-5">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Guests</div>

          <router-link
            class="button is-small"
            v-if="reservation"
            :to="{
              name: 'AddGuest',
              params: {
                reservationId: reservation.id,
                propertyId: reservation.propertyId
              }
            }"
            >Add Guest
          </router-link>
        </div>
        <div class="card-content">
          <guest-table
            v-if="guests.length > 0"
            :guests="guests"
            :hasRegistered="!!reservation.guestInformation"
            :primaryGuest="reservation.primaryGuest"
          />
        </div>
      </div>
    </div>
    <div class="mt-5" v-if="addtlGuests">
      <div class="card">
        <div class="card-header">
          <div class="card-header-title">Additional Guests</div>
        </div>
        <div class="card-content">
          <div v-for="(guest, index) in addtlGuests" :key="index" class="mb-4">
            {{ guest }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import guestTable from "@/components/guest/table.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { guestTable },
  computed: {
    ...mapState({
      reservation: (state) => state.reservation.item,
      guestInformation: (state) => state.reservation.item.guestInformation,
      guests: (state) => state.guest.collection,
      property: (state) => state.property.item
    }),
    vehicles() {
      if (this.guestInformation) {
        return this.guestInformation.vehicles || null;
      }
      return null;
    },
    addtlGuests() {
      if (this.guestInformation) {
        return this.guestInformation.additionalGuests || null;
      }
      return null;
    },
    status() {
      if (this.reservation.canceled) {
        return "Canceled";
      } else {
        if (!this.reservation.registered) {
          return "Pending";
        } else {
          if (this.reservation.checkedIn) {
            return "Checked In";
          } else {
            return "Registered";
          }
        }
      }
    }
  },
  methods: {
    ...mapActions({
      getReservation: "reservation/getItem",
      getGuests: "guest/getCollection",
      getProperty: "property/getItem",
      restoreReservation: "reservation/restoreReservation",
      cancelReservation: "reservation/cancelReservation",
      deleteReservation: "reservation/deleteReservation"
    }),
    handleRestoreReservation() {
      this.restoreReservation(this.reservation.id);
    },
    handleDeleteReservation() {
      this.$buefy.dialog.confirm({
        title: "Warning",
        message:
          "Deleting a reservation is permanent. All guests and other data associated with this reservation will be deleted.",
        cancelText: "Cancel",
        confirmText: "Delete",
        type: "is-warning",
        onConfirm: async () => {
          let reservation = this.reservation; // we need to hold the reservation infromation. it will be deleted from state
          await this.deleteReservation(reservation.id);
          this.$router.push({
            name: "ViewProperty",
            params: { id: reservation.propertyId }
          });
        }
      });
    },
    handleCancelReservation() {
      this.$buefy.dialog.confirm({
        title: "Warning",
        message:
          "Canceling a reservation is reservable. However, in canceling a reservaiton, you will be invalidating all passes associated to the reservation. Should you restore the reservation, you will need to send your guests new QR passes.",
        cancelText: "Do Not Cancel",
        confirmText: "Cancel Reservation",
        type: "is-warning",
        onConfirm: () => this.cancelReservation(this.reservation.id)
      });
    }
  },
  async mounted() {
    await this.getReservation(this.$route.params.id);
    await this.getGuests(this.$route.params.id);
    await this.getProperty(this.reservation.propertyId);
  }
};
</script>

<style lang="scss" scoped></style>

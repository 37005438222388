<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Reservations</li>
              <li>
                <span class="is-capitalized">{{ $route.params.type }}</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <router-link
                class="button is-dark"
                :to="{ name: 'AddReservation' }"
              >
                Add Reservation
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <component v-bind:is="showReservationsOfType"></component>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import current from "./ReservationsViewTabs/current.vue";
// import upcoming from "./ReservationsViewTabs/upcoming.vue";
import pending from "./ReservationsViewTabs/pending.vue";
import canceled from "./ReservationsViewTabs/canceled.vue";
import registered from "./ReservationsViewTabs/registered.vue";
export default {
  components: {
    current,
    // upcoming,
    canceled,
    pending,
    registered
  },
  data() {
    return {
      showReservationsOfType: ""
    };
  },
  computed: {
    ...mapGetters({
      propertiesGetter: "property/getProperties"
    }),
    ...mapState({
      properties: (state) => state.property.collection
    })
  },
  methods: {
    ...mapActions({
      getProperties: "property/getCollection",
      clearReservations: "reservation/clearCollectionByStatus",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    })
  },
  async mounted() {
    let statusType = this.$route.params.type;
    if (statusType) {
      this.showReservationsOfType = statusType;
    }
  },
  async beforeRouteUpdate(to, from, next) {
    this.activateLoading();
    await this.clearReservations(to.params.type); // clear out the one we're on
    await this.clearReservations(from.params.type); // clear out the one we were on
    this.showReservationsOfType = to.params.type;
    this.deactivateLoading();
    next();
  }
};
</script>

<style lang="scss" scoped></style>

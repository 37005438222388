<template>
  <div class="">
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Properties</li>
              <li v-if="!loading">
                {{ property.houseNumber }} {{ property.streetName }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section v-if="!loading">
      <div class="">
        <section class="card">
          <div class="card-header">
            <div class="card-header-title">Access</div>
            <div class="buttons">
              <router-link
                class="button is-small"
                v-if="property"
                :to="{
                  name: 'AddVisitor',
                  params: { propertyId: property.id }
                }"
              >
                Add Visitor
              </router-link>
              <router-link
                class="button is-small"
                v-if="property"
                :to="{
                  name: 'AddVendor',
                  params: { propertyId: property.id }
                }"
              >
                Add Vendor
              </router-link>
              <router-link
                class="button is-small"
                v-if="property"
                :to="{
                  name: 'AddManager',
                  params: { propertyId: property.id }
                }"
              >
                Add Manager
              </router-link>
            </div>
            <!-- <button type="button" class="button is-small">
              <span class="icon"><i class="fas fa-sync-alt"></i></span
              ><span>Refresh</span>
            </button> -->
          </div>
          <!--
          <div class="notification is-card-toolbar is-upper mb-0">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <div class="buttons has-addons">
                    <router-link
                      class="button"
                      v-if="property"
                      :to="{
                        name: 'AddVisitor',
                        params: { propertyId: property.id }
                      }"
                    >
                      Add Visitor
                    </router-link>
                    <router-link
                      class="button"
                      v-if="property"
                      :to="{
                        name: 'AddVendor',
                        params: { propertyId: property.id }
                      }"
                    >
                      Add Vendor
                    </router-link>
                    <router-link
                      class="button"
                      v-if="property && propertyRelationship.role === 'owner'"
                      :to="{
                        name: 'AddManager',
                        params: { propertyId: property.id }
                      }"
                    >
                      Add Manager
                    </router-link>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <form>
                    <div class="field has-addons">
                      <div class="control">
                        <input type="text" placeholder="Search" class="input" />
                      </div>
                      <div class="control">
                        <button type="submit" class="button is-primary">
                          <span class="icon"
                            ><i class="fas fa-search"></i
                          ></span>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

            </div>
          </div> -->
          <div class="card-content pt-1">
            <b-tabs v-model="accessTabs">
              <b-tab-item label="Visitors">
                <visitors-table :visitors="visitors" />
              </b-tab-item>

              <b-tab-item label="Vendors">
                <vendors-table :vendors="vendors" />
              </b-tab-item>

              <b-tab-item label="Managers">
                <managers-table :managers="managers" />
              </b-tab-item>
            </b-tabs>
          </div>
        </section>
        <section class="card">
          <div class="card-header">
            <div class="card-header-title">Reservations</div>
            <router-link
              class="button is-small"
              :to="{ name: 'AddReservation' }"
            >
              Add Reservation
            </router-link>
          </div>
          <div class="card-content pt-1">
            <b-tabs v-model="activeTab">
              <b-tab-item label="Current">
                <!-- <current-reservations-widget :properties="properties" /> -->
                <currentReservationsCard :property="property" />
              </b-tab-item>

              <!-- <b-tab-item label="Upcoming">
                <upcomingReservationsCard :property="property" />
              </b-tab-item> -->

              <b-tab-item label="Pending">
                <pendingReservationsCard :property="property" />
              </b-tab-item>
              <b-tab-item label="Registered">
                <registeredReservationsCard :property="property" />
              </b-tab-item>
              <b-tab-item label="Canceled">
                <canceledReservationsCard :property="property" />
              </b-tab-item>
            </b-tabs>
          </div>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
// import reservationCard from "@/components/reservation/card.vue";
// import currentReservationsWidget from "../../components/reservation/current-widget.vue";
// Views
import visitorsTable from "../../components/visitor/table.vue";
import managersTable from "../../components/manager/table.vue";
import vendorsTable from "../../components/vendor/table.vue";
import currentReservationsCard from "./PropertyViewTabs/current.vue";
// import upcomingReservationsCard from "./PropertyViewTabs/upcoming.vue";
import pendingReservationsCard from "./PropertyViewTabs/pending.vue";
import registeredReservationsCard from "./PropertyViewTabs/registered.vue";
import canceledReservationsCard from "./PropertyViewTabs/canceled.vue";
// import upcomingReservationsWidget from "../../components/reservation/upcoming-widget.vue";

import { mapState, mapActions, mapGetters } from "vuex";
export default {
  data() {
    return {
      loading: true,
      activeTab: 0,
      accessTabs: 0
      // currentTabComponent: currentReservationsWidget
    };
  },

  components: {
    visitorsTable,
    managersTable,
    vendorsTable,
    // currentReservationsWidget,
    currentReservationsCard,
    registeredReservationsCard,
    canceledReservationsCard,
    pendingReservationsCard
    // upcomingReservationsWidget
  },
  computed: {
    ...mapState({
      managers: (state) => state.manager.collection,
      reservations: (state) => state.reservation.collection,
      visitors: (state) => state.visitor.collection,
      vendors: (state) => state.vendor.collection,
      property: (state) => state.property.item,
      properties: (state) => state.property.collection,
      propertyRelationship: (state) => state.property.relationship
    }),
    ...mapGetters({
      propertiesGetter: "property/getProperties"
    })
  },
  methods: {
    ...mapActions({
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading",
      getManagers: "manager/getCollectionByProperty",
      getReservations: "reservation/getCollection",
      getVisitors: "visitor/getCollection",
      getVendors: "vendor/getCollection",
      // getGuests: "guest/getCollection",
      getProperty: "property/getItem",
      getProperties: "property/getCollection",
      getRelationshipToProperty: "property/getRelationshipToProperty"
    })
  },
  async mounted() {
    this.loading = true;
    this.activateLoading();
    await this.getProperty(this.$route.params.id);
    await this.getProperties();

    await this.getReservations([{ id: this.$route.params.id }]);
    await this.getVisitors(this.$route.params.id);
    await this.getVendors(this.$route.params.id);
    await this.getManagers(this.$route.params.id);
    await this.getRelationshipToProperty(this.$route.params.id);
    this.loading = false;
    this.deactivateLoading();
  }
};
</script>

<style lang="scss" scoped></style>

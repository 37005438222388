<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Vendor</li>
              <li>Add Vendor</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <property-address :propertyId="property.id" />
        </div>
      </div>

      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="field">
            <label class="label">Company Name <span>*</span></label>
            <div class="control">
              <input
                class="input"
                v-model="vendor.companyName"
                type="text"
                required
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">First Name <span>*</span></label>
                <div class="control">
                  <input
                    class="input"
                    v-model="vendor.nameFirst"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Last Name <span>*</span></label>
                <div class="control">
                  <input
                    class="input"
                    v-model="vendor.nameLast"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Company Address</label>
            <div class="control">
              <input
                class="input"
                v-model="vendor.companyAddress"
                type="text"
              />
            </div>
          </div>
          <div class="field">
            <label class="label">Send Notification <span>*</span></label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="vendor.notify" required>
                  <option value="no">No</option>
                  <option value="email">By Email</option>
                  <option value="sms">By Text/SMS</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label"
              >Email <span v-show="vendor.notify === 'email'">*</span></label
            >
            <div class="control">
              <input
                class="input"
                v-model="vendor.email"
                type="email"
                :required="vendor.notify === 'email'"
              />
            </div>
          </div>
          <div class="field">
            <label class="label"
              >Phone <span v-show="vendor.notify === 'sms'">*</span></label
            >
            <div class="control">
              <VuePhoneNumberInput
                v-model="phone"
                @update="handlePhone"
                :fetch-country="false"
                :required="vendor.notify === 'sms'"
              />
              <!-- <input
                class="input"
                v-model="vendor.phone"
                type="text"
                :required="vendor.notify === 'sms'"
              /> -->
            </div>
          </div>
          <div class="field">
            <label class="label">Company Website</label>
            <input class="input" v-model="vendor.companyWebsite" type="text" />
          </div>
          <b-field>
            <b-switch type="is-success" v-model="includePass"
              >Send Pass</b-switch
            >
          </b-field>
          <div>
            <button
              class="button is-success is-large is-fullwidth"
              type="submit"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import propertyAddress from "../../components/property/address.vue";

import { mapState, mapActions } from "vuex";
export default {
  components: { propertyAddress, VuePhoneNumberInput },

  data() {
    return {
      phone: "",

      includePass: false,

      vendor: {}
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item
    })
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      addVendor: "vendor/createItem",
      success: "alert/success",
      sendPass: "pass/sendPass",
      generatePass: "pass/generatePass"
    }),
    async handlePhone(newVal) {
      //formattedNumber
      if (newVal.isValid) {
        this.visitor.phone = newVal;
      }
    },
    async handleSubmit() {
      const { property, vendor } = this;
      let newVendor = await this.addVendor({ property, vendor });
      this.success("Added Vendor to Property");
      if (this.includePass) {
        let pass = await this.generatePass({
          type: "vendors",
          typeId: newVendor.id,
          propertyId: this.property.id
        });
        await this.sendPass({
          pass: pass,
          passholder: vendor,
          property: property
        });
        this.success("Pass Sent to Vendor");
      }

      this.$router.push({
        name: "ViewProperty",
        params: { id: this.$route.params.propertyId }
      });
    }
  },
  async mounted() {
    await this.getProperty(this.$route.params.propertyId);
  }
};
</script>

<style lang="scss" scoped></style>

<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Vendor</li>
              <li>Edit Vendor</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)"> Back </a>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="card" v-if="loaded">
      <div class="card-header">
        <div class="card-header-title">
          <property-address :propertyId="vendor.propertyId" />
        </div>
      </div>
      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="field">
            <label class="label">Company Name <span>*</span></label>
            <div class="control">
              <input
                class="input"
                v-model="localVendor.companyName"
                type="text"
                required
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">First Name <span>*</span></label>
                <div class="control">
                  <input
                    class="input"
                    v-model="localVendor.nameFirst"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Last Name <span>*</span></label>
                <div class="control">
                  <input
                    class="input"
                    v-model="localVendor.nameLast"
                    type="text"
                    required
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="field">
            <label class="label">Company Address</label>
            <div class="control">
              <input
                class="input"
                v-model="vendor.companyAddress"
                type="text"
              />
            </div>
          </div>
          <div class="field" v-if="vendor.notify">
            <label class="label" for="">Send Notification</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select v-model="localVendor.notify">
                  <option
                    v-for="(option, index) in notifyOptions"
                    :value="option.value"
                    :key="index"
                  >
                    {{ option.name }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label"
              >Email
              <span v-show="localVendor.notify === 'email'">*</span></label
            >
            <div class="control">
              <input
                class="input"
                v-model="localVendor.email"
                type="email"
                name=""
                id=""
                :required="localVendor.notify === 'email'"
              />
            </div>
          </div>
          <div class="field">
            <label class="label"
              >Phone <span v-show="localVendor.notify === 'sms'">*</span></label
            >
            <div class="control">
              <VuePhoneNumberInput
                v-model="phone"
                @update="handlePhone"
                :fetch-country="false"
                :no-use-browser-locale="true"
                :required="localVendor.notify === 'sms'"
              />
              <!-- <input
                class="input"
                v-model="localVendor.phone"
                type="text"
                :required="localVendor.notify === 'sms'"
              /> -->
            </div>
          </div>
          <div class="field">
            <label class="label">Company Website</label>
            <input class="input" v-model="vendor.companyWebsite" type="text" />
          </div>
          <b-field>
            <b-switch type="is-success" v-model="includePass"
              >Send Pass</b-switch
            >
          </b-field>
          <div>
            <button class="button is-success is-fullwidth" type="submit">
              Update
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import propertyAddress from "../../components/property/address.vue";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapState, mapActions } from "vuex";
export default {
  components: { propertyAddress, VuePhoneNumberInput },

  data() {
    return {
      phone: "",

      includePass: false,
      loaded: false,
      localVendor: {
        companyName: "",
        companyAddress: "",
        companyWebsite: "",
        nameFirst: "",
        nameLast: "",
        email: "",
        phone: "",
        type: "",
        notify: ""
      },
      notifyOptions: [
        { name: "Text/SMS", value: "sms" },
        { name: "Email", value: "email" },
        { name: "None", value: "none" }
      ]
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      vendor: (state) => state.vendor.item
    })
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      getVendor: "vendor/getItem",
      updateVendor: "vendor/updateItem",
      success: "alert/success",
      sendPass: "pass/sendPass",
      generatePass: "pass/generatePass",
      activateLoading: "loading/activateLoading",
      deactivateLoading: "loading/deactivateLoading"
    }),
    async handlePhone(newVal) {
      //formattedNumber
      if (newVal.isValid) {
        this.localVendor.phone = newVal;
      }
    },
    async handleSubmit() {
      this.activateLoading;
      await this.updateVendor({ id: this.vendor.id, ...this.localVendor });
      this.success("Updated Vendor");
      if (this.includePass) {
        let pass = await this.generatePass({
          type: "vendors",
          typeId: this.vendor.id,
          propertyId: this.property.id
        });
        await this.sendPass({
          pass: pass,
          passholder: this.localVendor,
          property: this.property
        });
        this.success("Pass Sent to Vendor");
      }
      this.$router.push({
        name: "ViewProperty",
        params: { id: this.property.id }
      });
      this.deactivateLoading;
    }
  },
  async mounted() {
    this.loaded = false;
    await this.getVendor(this.$route.params.id);
    await this.getProperty(this.vendor.propertyId);
    for (let variable in this.localVendor) {
      this.localVendor[variable] = this.vendor[variable];
    }
    if (this.localVendor.phone) {
      this.phone = this.localVendor.phone.formatNational;
    }
    this.loaded = true;
  }
};
</script>

<style lang="scss" scoped></style>

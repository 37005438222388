<template>
  <b-loading :is-full-page="true" :active.sync="loading.loading">
    <b-icon pack="fad" icon="spinner" size="is-large" custom-class="fa-spin"></b-icon>
  </b-loading>
</template>

<script>
  import {
    mapState
  } from "vuex";

  export default {
    computed: {
      ...mapState(["loading"])
    }
  };
</script>

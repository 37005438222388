<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Properties</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section>
      <div class="columns is-multiline">
        <div
          class="column is-one-third"
          v-for="property in properties"
          :key="property.id"
        >
          <property-card :property="property" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import propertyCard from "@/components/property/card.vue";
// import reservationListItem from "@/components/reservation/list-item.vue";
import { mapActions, mapState, mapGetters } from "vuex";
export default {
  components: {
    propertyCard
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      propertiesGetter: "property/getProperties"
    }),
    ...mapState({
      properties: (state) => state.property.collection
    })
  },
  methods: {
    ...mapActions({
      getProperties: "property/getCollection"
    })
  },
  async mounted() {
    await this.getProperties();
  }
};
</script>

<style lang="scss" scoped></style>

import Vue from "vue";
import App from "./App.vue";
import Buefy from "buefy";
import InstantSearch from "vue-instantsearch";
import Vue2Filters from "vue2-filters";

import "buefy/dist/buefy.css";
import "./scss/main.scss";

import "./registerServiceWorker";
import router from "./router";
import { store } from "./store";

Vue.use(Buefy, {
  defaultIconPack: "fas"
});
Vue.use(require("vue-moment"));
Vue.use(InstantSearch);
Vue.use(Vue2Filters);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount("#app");

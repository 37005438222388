const state = {
  loading: false
};

const mutations = {
  ACTIVATE_LOADING(state) {
    state.loading = true;
  },
  DEACTIVATE_LOADING(state) {
    state.loading = false;
  }
};

const actions = {
  activateLoading({
    commit
  }, howLong) {
    commit("ACTIVATE_LOADING");
    if (typeof howLong === "number") {
      setTimeout(() => {
        commit("DEACTIVATE_LOADING");
      }, howLong);
    }
  },
  deactivateLoading({
    commit
  }) {
    commit("DEACTIVATE_LOADING");
  }
};

export const loading = {
  namespaced: true,
  state,
  mutations,
  actions
};

import { firebase } from "../firebaseConfig";
import emails from "../notifications/emails";
import sms from "../notifications/sms";
const actions = {
  // eslint-disable-next-line
  async createItem({}, item) {
    try {
      let passObj = {
        ...item,
        activeDateUnix: new Date().getTime() / 1000,
        createdBy: firebase.auth().currentUser.uid
      };
      const newPass = await firebase
        .firestore()
        .collection("passes")
        .add(passObj);

      const lastSix = newPass.id.substr(newPass.id.length - 6).toUpperCase();

      await firebase
        .firestore()
        .collection("passes")
        .doc(newPass.id)
        .update({
          code: lastSix
        });

      return Promise.resolve({ id: newPass.id, ...passObj });
    } catch (error) {
      console.error("createItem error", error);
      return Promise.reject();
    }
  },
  async getItem({ commit }, passId) {
    try {
      let passQuery = await firebase
        .firestore()
        .collection("passes")
        .doc(passId)
        .get();
      commit("SET_ITEM", {
        id: passQuery.id,
        ...passQuery.data()
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItem error", error);
      return Promise.reject();
    }
  },
  // eslint-disable-next-line
  async registrationRequest({}, payload) {
    // check notification type
    if (payload.primaryGuest.notify === "sms") {
      let compiledTemplate = sms.generator(
        payload,
        sms.registrationRequestTemplate
      );

      // sms goes to twilio
      firebase
        .firestore()
        .collection("sms")
        .add({
          to: payload.primaryGuest.phone,
          message: compiledTemplate
        })
        .then(() => console.log("Queued sms for delivery!"));
    } else {
      // email goes to sendgrid
      let compiledTemplate = emails.generator(
        payload,
        emails.registrationRequestTemplate
      );
      // console.log("compiledTemplate", compiledTemplate);
      // check notification type

      // email goes to sendgrid

      // sms goes to twilio
      firebase
        .firestore()
        .collection("emails")
        .add({
          to: payload.primaryGuest.email,
          subject: "Emerald Island Resort Guest Registration",
          html: compiledTemplate
        })
        .then(() => console.log("Queued email for delivery!"));
    }
  },
  async generatePass({ dispatch }, payload) {
    try {
      // look up any old passes for this type and id
      const passesQuery = await firebase
        .firestore()
        .collection("passes")
        .where("type", "==", payload.type)
        .where("typeId", "==", payload.typeId)
        .where("propertyId", "==", payload.propertyId)
        .get();
      // delete any old passes found
      passesQuery.docs.forEach(async (pass) => {
        await firebase
          .firestore()
          .collection("passes")
          .doc(pass.id)
          .delete();
      });

      let pass = await dispatch("createItem", payload);
      // send the pass to the user
      // await dispatch("sendPass", )
      // add new pass
      return Promise.resolve(pass);
      // send new pass out
    } catch (error) {
      return Promise.reject(error);
    }
  },
  // eslint-disable-next-line
  async sendPass({}, payload) {
    try {
      if (payload.passholder.notify === "sms") {
        const code = payload.pass.id
          .substr(payload.pass.id.length - 6)
          .toUpperCase();
        let compiledTemplate = sms.generator(
          { passholder: payload.passholder, pass: { code, ...payload.pass } },
          sms.passTemplate
        );

        // sms goes to twilio
        firebase
          .firestore()
          .collection("sms")
          .add({
            to: payload.passholder.phone,
            message: compiledTemplate
          })
          .then(() => console.log("Queued sms for delivery!"));
      } else {
        // email goes to sendgrid
        let compiledTemplate = emails.generator(payload, emails.passTemplate);

        firebase
          .firestore()
          .collection("emails")
          .add({
            to: payload.passholder.email,
            subject: "Emerald Island Resort - Pass",
            html: compiledTemplate
          })
          .then(() => console.log("Queued email for delivery!"));
      }
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  async updateItem({ commit }, payload) {
    const { id, ...everythingElse } = payload;
    try {
      await firebase
        .firestore()
        .collection("passes")
        .doc(id)
        .update(everythingElse);
      commit("SET_ITEM", {
        payload
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItem error", error);
      return Promise.reject();
    }
  },
  async removeItem({ commit }, passId) {
    try {
      await firebase
        .firestore()
        .collection("passes")
        .doc(passId)
        .delete();
      commit("REMOTE_ITEM_FROM_COLLECTION", {
        id: passId
      });
      return Promise.resolve();
    } catch (error) {
      console.error("deleteItem error", error);
      return Promise.reject();
    }
  }
};

export const pass = {
  namespaced: true,
  actions
};

<template>
  <router-link :to="{ name: 'ViewProperty', params: { id: propertyId } }">
    {{ property.houseNumber }} {{ property.streetName }}
  </router-link>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["propertyId"],
  computed: {
    ...mapState({
      properties: (state) => state.property.collection
    }),
    property: function () {
      if (this.properties.length > 0) {
        let item = this.properties.find(
          (element) => element.id === this.propertyId
        );
        return item;
      }
      return false;
    }
  }
};
</script>

<style lang="scss" scoped></style>

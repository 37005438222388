<template>
  <b-table :data="guests">
    <b-table-column field="id" label="Name" v-slot="props">
      {{ props.row.nameFirst }} {{ props.row.nameLast }}
    </b-table-column>

    <b-table-column field="id" label="Primary" v-slot="props">
      <span v-if="primaryGuest === props.row.id">Yes</span>
    </b-table-column>
    <b-table-column field="email" label="Email" v-slot="props">
      {{ props.row.email }}
    </b-table-column>
    <b-table-column field="phone" label="Phone" v-slot="props">
      <a
        :href="`${props.row.phone.uri}`"
        v-if="props.row.phone && props.row.phone.formatNational"
      >
        <span
          v-if="props.row.phone && props.row.phone.countryCallingCode"
          class="mr-3"
          >+{{ props.row.phone.countryCallingCode }}</span
        >
        <span v-if="props.row.phone && props.row.phone.formatNational">{{
          props.row.phone.formatNational
        }}</span></a
      >
    </b-table-column>
    <b-table-column field="notify" label="Notify By" v-slot="props">
      {{ props.row.notify }}
    </b-table-column>
    <b-table-column v-slot="props">
      <b-dropdown aria-role="list" position="is-bottom-left">
        <template #trigger>
          <b-button icon-pack="fas" type="is-outline" rounded size="is-small">
            <i class="fas fa-ellipsis-v"></i>
          </b-button>
        </template>

        <b-dropdown-item
          @click="handleSendRegistrationRequest(props.row)"
          aria-role="listitem"
          v-if="primaryGuest === props.row.id && !props.row.guestInformation"
        >
          Send Registration Request
        </b-dropdown-item>
        <b-dropdown-item
          aria-role="listitem"
          class="is-text is-block is-fullwidth"
          @click="handleSendPass(props.row)"
          :disabled="!hasRegistered"
        >
          Send Access Pass
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <router-link
            :to="{
              name: 'EditGuest',
              params: { id: props.row.id }
            }"
            >Edit</router-link
          >
        </b-dropdown-item>
        <b-dropdown-item aria-role="listitem">
          <a @click="handleRemoveGuest(props.row.id)">
            Remove
          </a></b-dropdown-item
        >
      </b-dropdown>
    </b-table-column>
  </b-table>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  props: ["guests", "primaryGuest", "hasRegistered"],
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    })
  },
  methods: {
    ...mapActions({
      removeGuest: "guest/deleteItem",
      generatePass: "pass/generatePass",
      sendPass: "pass/sendPass",
      sendRegistrationRequest: "pass/registrationRequest",
      success: "alert/success"
    }),
    async handleSendPass(guest) {
      let pass = await this.generatePass({
        type: "guests",
        typeId: guest.id,
        propertyId: this.property.id
      });
      await this.sendPass({ pass: pass, passholder: guest });
      this.success("Pass Sent to Guest");
    },
    async handleSendRegistrationRequest(primaryGuest) {
      let { property, reservation } = this;
      await this.sendRegistrationRequest({
        primaryGuest,
        property,
        reservation
      });
      this.success("Registration Request Sent");
    },
    async handleRemoveGuest(id) {
      await this.removeGuest(id);
      this.success("Guest Removed");
    }
  }
};
</script>

<style lang="scss" scoped></style>

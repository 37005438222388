// building templates!
import generator from "./generator";
// the templates to be built
import registrationRequestTemplate from "./registrationRequest.template";
import passTemplate from "./pass.template";
export default {
  generator,
  registrationRequestTemplate,
  passTemplate
};

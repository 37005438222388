var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-5"},[_c('ais-instant-search',{attrs:{"search-client":_vm.searchClient,"index-name":"properties"}},[_c('div',{staticClass:"notification"},[_c('ais-search-box',{attrs:{"placeholder":"Search guests, reservations, visitors, license plates, properties, and more"}})],1),_c('ais-hits',{attrs:{"class-names":{ 'ais-Hits-item': 'is-block' }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Property")]),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(item.houseNumber)+" "+_vm._s(item.streetName)+" ")])])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('router-link',{staticClass:"button",attrs:{"to":{
                  name: 'ViewProperty',
                  params: { id: item.objectID }
                }}},[_vm._v("View Property")])],1)])])]}}])}),_c('ais-index',{attrs:{"index-name":"guests"}},[_c('ais-hits',{attrs:{"class-names":{ 'ais-Hits-item': 'is-block' }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Guest")]),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(item.nameFirst)+" "+_vm._s(item.nameLast)+" ")])])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('router-link',{staticClass:"button",attrs:{"to":{
                    name: 'ViewReservation',
                    params: { id: item.reservationId }
                  }}},[_vm._v("View Reservation")])],1)])])]}}])})],1),_c('ais-index',{attrs:{"index-name":"visitors"}},[_c('ais-hits',{attrs:{"class-names":{ 'ais-Hits-item': 'is-block' }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                  var item = ref.item;
return [_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Visitor")]),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(item.nameFirst)+" "+_vm._s(item.nameLast)+" ")])])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('router-link',{staticClass:"button",attrs:{"to":{
                      name: 'ViewProperty',
                      params: { id: item.propertyId }
                    }}},[_vm._v("View Property")])],1)])])])]}}])})],1),_c('ais-index',{attrs:{"index-name":"vendors"}},[_c('ais-hits',{attrs:{"class-names":{ 'ais-Hits-item': 'is-block' }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Company")]),_c('p',{staticClass:"title"},[_vm._v(_vm._s(item.companyName))])])]),_c('div',{staticClass:"level-item"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Vendor Name")]),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(item.nameFirst)+" "+_vm._s(item.nameLast)+" ")])])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('router-link',{staticClass:"button",attrs:{"to":{
                      name: 'ViewProperty',
                      params: { id: item.propertyId }
                    }}},[_vm._v("View Property")])],1)])])])]}}])})],1),_c('ais-index',{attrs:{"index-name":"reservations"}},[_c('ais-hits',{attrs:{"class-names":{ 'ais-Hits-item': 'is-block' }},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                    var item = ref.item;
return [_c('div',{staticClass:"level is-mobile"},[_c('div',{staticClass:"level-left"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('p',{staticClass:"heading"},[_vm._v("Reservation")]),_c('p',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm._f("moment")(item.dateArrivalUnix,"MMM Do YYYY"))+" to "+_vm._s(_vm._f("moment")(item.dateDepartureUnix,"MMM Do YYYY"))+" ")])])])]),_c('div',{staticClass:"level-right"},[_c('div',{staticClass:"level-item"},[_c('div',[_c('router-link',{staticClass:"button",attrs:{"to":{
                      name: 'ViewReservation',
                      params: { id: item.objectID }
                    }}},[_vm._v("View Reservation")])],1)])])])]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
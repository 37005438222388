import { firebase } from "../firebaseConfig";
import _ from "lodash";
const state = {
  item: null,
  collection: []
};

const mutations = {
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  CLEAR_ITEM(state) {
    state.item = null;
  },
  SET_COLLECTION(state, payload) {
    state.collection = payload;
  },
  ADD_ITEM_TO_COLLECTION(state, payload) {
    state.collection.unshift(payload);
  },
  REMOTE_ITEM_FROM_COLLECTION(state, payload) {
    const i = state.collection.map((item) => item.id).indexOf(payload.id);
    state.collection.splice(i, 1);
  },
  CLEAR_COLLECTION(state) {
    state.collection = [];
  }
};

const actions = {
  async createItem({ commit }, item) {
    try {
      const { visitor, property } = item;

      let newVisitor = await firebase
        .firestore()
        .collection("visitors")
        .add({
          ...visitor,
          propertyId: property.id,
          createdTimestamp: new Date(),
          createdBy: firebase.auth().currentUser.uid
        });

      const tempItem = {
        id: newVisitor.id,
        ...visitor
      };
      commit("ADD_ITEM_TO_COLLECTION", tempItem);
      commit("SET_ITEM", tempItem);
      return Promise.resolve(tempItem);
    } catch (error) {
      console.error("createItem error", error);
      return Promise.reject();
    }
  },
  async deleteItem({ commit }, itemId) {
    try {
      await firebase
        .firestore()
        .collection("visitors")
        .doc(itemId)
        .delete();

      commit("REMOTE_ITEM_FROM_COLLECTION", { id: itemId });
      return Promise.resolve();
    } catch (error) {
      console.error("createDelete error", error);
      return Promise.reject();
    }
  },
  async getItem({ commit }, visitorId) {
    try {
      let visitorQuery = await firebase
        .firestore()
        .collection("visitors")
        .doc(visitorId)
        .get();
      commit("SET_ITEM", {
        id: visitorQuery.id,
        ...visitorQuery.data()
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItem error", error);
      return Promise.reject();
    }
  },
  async updateItem({ commit }, payload) {
    const { id, ...everythingElse } = payload;
    try {
      await firebase
        .firestore()
        .collection("visitors")
        .doc(id)
        .update(_.omitBy(everythingElse, _.isNil));
      commit("NOTHING");
      commit("SET_ITEM", {
        ...payload
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItem error", error);
      return Promise.reject();
    }
  },
  async getCollection({ commit }, propertyId) {
    commit("CLEAR_COLLECTION");
    try {
      let visitorQuery = await firebase
        .firestore()
        .collection("visitors")
        .where("propertyId", "==", propertyId)
        .get();

      visitorQuery.docs.forEach((visitor) => {
        let docObj = {
          id: visitor.id,
          ...visitor.data()
        };

        commit("ADD_ITEM_TO_COLLECTION", docObj);
      });
      return Promise.resolve();
    } catch (error) {
      console.error("getItems error", error);
      return Promise.reject();
    }
  },
  async removeItem({ commit }, visitorId) {
    try {
      await firebase
        .firestore()
        .collection("visitors")
        .doc(visitorId)
        .delete();
      commit("REMOTE_ITEM_FROM_COLLECTION", {
        id: visitorId
      });
      return Promise.resolve();
    } catch (error) {
      console.error("deleteItem error", error);
      return Promise.reject();
    }
  }
};

const getters = {};
export const visitor = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Visitor</li>
              <li>Add Visitor</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card">
      <div class="card-header">
        <div class="card-header-title">
          <property-address :propertyId="propertyId" />
        </div>
      </div>
      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="field">
            <label class="label" for="">First Name <span>*</span></label>
            <div class="control">
              <input
                class="input"
                v-model="visitor.nameFirst"
                type="text"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for="">Last Name <span>*</span></label>
            <div class="control">
              <input
                class="input"
                v-model="visitor.nameLast"
                type="text"
                required
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for="">Send Notification</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select name="" id="" v-model="visitor.notify">
                  <option value="no">No</option>
                  <option value="email">By Email</option>
                  <option value="sms">By Text/SMS</option>
                </select>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label" for=""
              >Email <span v-show="visitor.notify === 'email'">*</span></label
            >
            <div class="control">
              <input
                class="input"
                v-model="visitor.email"
                type="email"
                name=""
                id=""
                :required="visitor.notify === 'email'"
              />
            </div>
          </div>
          <div class="field">
            <label class="label" for=""
              >Phone <span v-show="visitor.notify === 'sms'">*</span></label
            >

            <div class="control">
              <VuePhoneNumberInput
                v-model="phone"
                @update="handlePhone"
                :fetch-country="false"
                :required="visitor.notify === 'sms'"
              />
              <!-- <input
                class="input"
                v-model="visitor.phone"
                type="text"
                :required="visitor.notify === 'sms'"
              /> -->
            </div>
          </div>
          <div class="field">
            <label class="label" for="">Visit Frequency <span>*</span></label>
            <div class="control">
              <div class="select is-fullwidth">
                <select name="" id="" v-model="visitor.type">
                  <option value="once">Once</option>
                  <option value="reoccurring">Between These Dates</option>
                  <option value="ongoing">Ongoing</option>
                </select>
              </div>
            </div>
          </div>
          <div v-if="visitor.type === 'reoccurring'">
            <div class="field">
              <label class="label" for=""
                >Allow Access Starting On
                <span v-show="visitor.type === 'reoccuring'">*</span></label
              >
              <b-datetimepicker
                v-model="visitor.dateArrival"
                placeholder="Click to select..."
                icon-pack="fas"
                icon="calendar"
                horizontal-time-picker
                :required="visitor.type === 'reoccurring'"
                :timepicker="timepicker"
              >
              </b-datetimepicker>
            </div>
            <div class="field">
              <label class="label" for=""
                >End Access On<span v-show="visitor.type === 'reoccuring'"
                  >*</span
                ></label
              >
              <b-datetimepicker
                v-model="visitor.dateDeparture"
                placeholder="Click to select..."
                icon-pack="fas"
                icon="calendar"
                horizontal-time-picker
                :required="visitor.type === 'reoccurring'"
                :timepicker="timepicker"
              >
              </b-datetimepicker>
            </div>
          </div>
          <b-field class="mt-4">
            <b-switch type="is-success" v-model="includePass"
              >Send Pass</b-switch
            >
          </b-field>
          <div>
            <button
              class="button is-success is-large is-fullwidth"
              type="submit"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

import propertyAddress from "../../components/property/address.vue";
import { mapState, mapActions } from "vuex";
export default {
  components: { propertyAddress, VuePhoneNumberInput },
  data() {
    return {
      phone: "",
      includePass: false,
      visitor: {
        type: "once"
      },
      timepicker: {
        incrementMinutes: 15
      }
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item
    }),
    propertyId() {
      return this.$route.params.propertyId;
    }
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      addVisitor: "visitor/createItem",
      success: "alert/success",
      sendPass: "pass/sendPass",
      generatePass: "pass/generatePass"
    }),
    async handlePhone(newVal) {
      //formattedNumber
      if (newVal.isValid) {
        this.visitor.phone = newVal;
      }
    },
    async handleSubmit() {
      const { property, visitor } = this;
      let newVisitor = await this.addVisitor({ property, visitor });
      this.success("Added Visitor to Property");

      if (this.includePass) {
        let pass = await this.generatePass({
          type: "visitors",
          typeId: newVisitor.id,
          propertyId: this.propertyId
        });
        await this.sendPass({
          pass: pass,
          passholder: newVisitor,
          property: property
        });
        this.success("Pass Sent to Visitor");
      }
      this.$router.push({
        name: "ViewProperty",
        params: { id: this.$route.params.propertyId }
      });
    }
  },
  async mounted() {
    await this.getProperty(this.$route.params.propertyId);
  }
};
</script>

<style lang="scss" scoped></style>

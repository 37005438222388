<template>
  <div>
    <section class="section is-title-bar px-0">
      <div class="level">
        <div class="level-left">
          <div class="level-item">
            <ul>
              <li>Guest</li>
              <li>Add</li>
            </ul>
          </div>
        </div>
        <div class="level-right">
          <div class="level-item">
            <div class="buttons is-right">
              <a class="is-link" @click="$router.go(-1)">
                Back
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div class="card">
      <div class="card-header" v-if="property && reservation">
        <div class="card-header-title">
          Reservation for: {{ property.houseNumber }} {{ property.streetName }}
          {{ reservation.dateArrivalUnix | moment("ddd, MMM Do YYYY") }} to
          {{ reservation.dateDepartureUnix | moment("ddd, MMM Do YYYY") }}
        </div>
      </div>
      <div class="card-content">
        <form @submit.prevent="handleSubmit">
          <div class="columns">
            <div class="column">
              <div class="field">
                <label class="label">First Name</label>
                <div class="control">
                  <input class="input" v-model="guest.nameFirst" type="text" />
                </div>
              </div>
            </div>
            <div class="column">
              <div class="field">
                <label class="label">Last Name</label>
                <div class="control">
                  <input class="input" v-model="guest.nameLast" type="text" />
                </div>
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Email</label
            ><input
              class="input"
              v-model="guest.email"
              type="email"
              name=""
              id=""
            />
          </div>
          <div class="field">
            <label class="label">Phone</label>
            <div class="control">
              <VuePhoneNumberInput
                v-model="phone"
                @update="handlePhone"
                :fetch-country="false"
                :required="guest.notify === 'sms'"
              />
              <!-- <input class="input" v-model="guest.phone" type="text" /> -->
            </div>
          </div>
          <div class="field">
            <label class="label">Send Notification</label>
            <div class="control">
              <div class="select is-fullwidth">
                <select name="" id="" v-model="guest.notify">
                  <option value="no">No</option>
                  <option value="email">By Email</option>
                  <option value="sms">By Text/SMS</option>
                </select>
              </div>
            </div>
          </div>
          <b-field class="mt-4">
            <b-checkbox v-model="makePrimary" size="is-medium"
              >Make Primary Guest</b-checkbox
            >
          </b-field>
          <b-field v-if="makePrimary" class="mt-4">
            <b-checkbox v-model="includeRegistration" size="is-medium"
              >Send Registration Notification</b-checkbox
            >
          </b-field>

          <div>
            <button
              class="button is-success is-large is-fullwidth"
              type="submit"
            >
              Add
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import { mapState, mapActions } from "vuex";
export default {
  components: { VuePhoneNumberInput },

  data() {
    return {
      phone: "",
      includeRegistration: false,
      makePrimary: false,
      guest: {}
    };
  },
  computed: {
    ...mapState({
      property: (state) => state.property.item,
      reservation: (state) => state.reservation.item
    })
  },
  methods: {
    ...mapActions({
      getProperty: "property/getItem",
      getReservation: "reservation/getItem",
      addGuest: "guest/createItem",
      success: "alert/success"
    }),
    async handlePhone(newVal) {
      //formattedNumber
      if (newVal.isValid) {
        this.guest.phone = newVal;
      }
    },
    async handleSubmit() {
      await this.addGuest({
        reservation: { id: this.reservation.id, makePrimary: this.makePrimary },
        guest: {
          propertyId: this.property.id,
          ...this.guest
        }
      });
      this.success("Added Guest to Reservation");
      this.$router.push({
        name: "ViewReservation",
        params: { id: this.reservation.id }
      });
    }
  },
  async mounted() {
    await this.getProperty(this.$route.params.propertyId);
    await this.getReservation(this.$route.params.reservationId);
  }
};
</script>

<style lang="scss" scoped></style>

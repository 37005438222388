<template>
  <div v-if="loaded">
    <div class="card">
      <div class="card-content">
        <reservation-table :reservations="reservations" :showAddress="true" />
      </div>
    </div>
  </div>
</template>

<script>
import reservationTable from "../../../components/reservation/table.vue";
import { mapActions, mapState } from "vuex";
export default {
  props: ["property"],
  components: { reservationTable },
  data() {
    return {
      loaded: false
    };
  },
  computed: {
    ...mapState({
      reservations: (state) => state.reservation.pending
    })
  },
  methods: {
    ...mapActions({
      getReservations: "reservation/getPendingReservations"
    })
  },
  async mounted() {
    this.loaded = false;
    if (this.property) {
      await this.getReservations([{ id: this.property.id }]);
      this.loaded = true;
    } else {
      await this.getReservations();
      setTimeout(() => {
        this.loaded = true;
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped></style>

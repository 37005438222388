import { firebase } from "../firebaseConfig";

const state = {
  item: {
    dateArrival: null,
    dateDeparture: null
  },
  collection: [],
  pending: [],
  registered: [],
  approved: [],
  active: [],
  canceled: [],
  current: []
};

const mutations = {
  SET_ITEM(state, payload) {
    state.item = payload;
  },
  UPDATE_ITEM(state, payload) {
    state.item[payload.key] = payload.value;
  },
  CLEAR_ITEM(state) {
    state.item = null;
  },
  SET_COLLECTION(state, payload) {
    state.collection = payload;
  },
  ADD_ITEM_TO_COLLECTION(state, payload) {
    state.collection.unshift(payload);
  },
  UPDATE_ITEM_IN_COLLECTION(state, payload) {
    const i = state.collection.map((item) => item.id).indexOf(payload.id);
    state.collection[i] = payload;
  },
  ADD_ITEM_TO_STATUS_COLLECTION(state, payload) {
    const { docObj, status } = payload;
    state[status].unshift(docObj);
  },
  REMOTE_ITEM_FROM_COLLECTION(state, payload) {
    const i = state.collection.map((item) => item.id).indexOf(payload.id);
    state.collection.splice(i, 1);
  },
  REMOTE_ITEM_FROM_STATUS_COLLECTION(state, payload) {
    const { docObj, status } = payload;
    const i = state.collection.map((docObj) => docObj.id).indexOf(docObj.id);
    state[status].splice(i, 1);
  },
  CLEAR_COLLECTION(state) {
    state.collection = [];
  },
  CLEAR_STATUS_COLLECTION(state, status) {
    state[status] = [];
  }
};

const actions = {
  async createItem({ commit }, reservation) {
    try {
      reservation.status = "pending";
      if (reservation.dateArrival) {
        reservation.dateArrivalUnix =
          new Date(reservation.dateArrival).getTime() / 1000;
        reservation.dateArrivalTimestamp = new Date(reservation.dateArrival);
      }
      if (reservation.dateDeparture) {
        reservation.dateDepartureUnix =
          new Date(reservation.dateDeparture).getTime() / 1000;
        reservation.dateDepartureTimestamp = new Date(
          reservation.dateDeparture
        );
      }

      let newReservation = await firebase
        .firestore()
        .collection("reservations")
        .add({ ...reservation });

      const lastSix = newReservation.id
        .substr(newReservation.id.length - 6)
        .toUpperCase();

      await firebase
        .firestore()
        .collection("reservations")
        .doc(newReservation.id)
        .update({
          code: lastSix
        });

      let reservationObject = {
        id: newReservation.id,
        ...reservation
      };

      commit("ADD_ITEM_TO_COLLECTION", reservationObject);
      commit("SET_ITEM", reservationObject);
      return Promise.resolve(reservationObject);
    } catch (error) {
      console.error("createItem error", error);
      return Promise.reject();
    }
  },
  async updateItem({ commit }, reservation) {
    try {
      //reservation.status = "pending";
      if (reservation.dateArrival) {
        reservation.dateArrivalUnix =
          new Date(reservation.dateArrival).getTime() / 1000;
        reservation.dateArrivalTimestamp = new Date(reservation.dateArrival);
      }
      if (reservation.dateDeparture) {
        reservation.dateDepartureUnix =
          new Date(reservation.dateDeparture).getTime() / 1000;
        reservation.dateDepartureTimestamp = new Date(
          reservation.dateDeparture
        );
      }

      await firebase
        .firestore()
        .collection("reservations")
        .doc(reservation.id)
        .update({ ...reservation });

      let reservationObject = {
        ...reservation
      };

      commit("UPDATE_ITEM_IN_COLLECTION", reservationObject);
      commit("SET_ITEM", reservationObject);
      return Promise.resolve(reservationObject);
    } catch (error) {
      console.error("createItem error", error);
      return Promise.reject();
    }
  },
  async clearCollectionByStatus({ commit }, status) {
    commit("CLEAR_STATUS_COLLECTION", status);
    return Promise.resolve();
  },
  async clearAllCollections({ commit }) {
    let status = ["active", "pending", "approved", "canceled"];
    for (let index = 0; index < status.length; index++) {
      const stat = status[index];
      commit("CLEAR_STATUS_COLLECTION", stat);
    }
    return Promise.resolve();
  },
  async getCollectionByStatus({ commit, dispatch }, payload) {
    try {
      let { properties, status } = payload;
      commit("CLEAR_STATUS_COLLECTION", status);

      let allProperties = null;
      if (!payload.properties) {
        allProperties = await dispatch(
          "property/getCollection",
          {},
          { root: true }
        );
      }

      if (!allProperties) {
        allProperties = properties;
      }

      allProperties.forEach(async (property) => {
        let reservationsQuery = await firebase
          .firestore()
          .collection("reservations")
          .where("propertyId", "==", property.id)
          .where("status", "==", status)
          .get();

        reservationsQuery.docs.forEach(async (reservation) => {
          let docObj = {
            id: reservation.id,
            // snap: reservation,
            ...reservation.data()
          };
          if (docObj.primaryGuest) {
            let guest = await firebase
              .firestore()
              .collection("guests")
              .doc(docObj.primaryGuest)
              .get();
            docObj.primaryGuest = {
              id: guest.id,
              ...guest.data()
            };
          }
          commit("ADD_ITEM_TO_STATUS_COLLECTION", { docObj, status });
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  async getCollection({ commit }, properties) {
    commit("CLEAR_COLLECTION");
    try {
      properties.forEach(async (property) => {
        let reservationsQuery = await firebase
          .firestore()
          .collection("reservations")
          .where("propertyId", "==", property.id)
          .get();

        reservationsQuery.docs.forEach(async (reservation) => {
          let docObj = {
            id: reservation.id,
            // snap: reservation,
            ...reservation.data()
          };
          if (docObj.primaryGuest) {
            let guest = await firebase
              .firestore()
              .collection("guests")
              .doc(docObj.primaryGuest)
              .get();
            docObj.primaryGuest = {
              id: guest.id,
              ...guest.data()
            };
          }
          commit("ADD_ITEM_TO_COLLECTION", docObj);
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  async getPendingReservations({ commit, dispatch }, properties) {
    commit("CLEAR_STATUS_COLLECTION", "pending");
    try {
      let allProperties = null; // a container for our properties
      if (!properties) {
        // these are the properties coming in from the request
        // this gets all the properties available because we're getting the whole collection
        allProperties = await dispatch(
          "property/getCollection",
          {},
          { root: true }
        );
      }

      if (!allProperties) {
        // is we've got no properties from getting properties
        allProperties = properties; // then let's just set it to the single prperty coming in
      }

      allProperties.forEach(async (property) => {
        let reservationsQuery = await firebase
          .firestore()
          .collection("reservations")
          .where("propertyId", "==", property.id)
          .where("registered", "==", null)
          .where("canceled", "==", null)
          .where("checkedIn", "==", null)
          .get();

        reservationsQuery.docs.forEach(async (reservation) => {
          let docObj = {
            id: reservation.id,
            // snap: reservation,
            ...reservation.data()
          };
          if (docObj.primaryGuest) {
            let guest = await firebase
              .firestore()
              .collection("guests")
              .doc(docObj.primaryGuest)
              .get();
            docObj.primaryGuest = {
              id: guest.id,
              ...guest.data()
            };
          }
          commit("ADD_ITEM_TO_STATUS_COLLECTION", {
            docObj,
            status: "pending"
          });
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  async getRegisteredReservations({ commit }, properties) {
    commit("CLEAR_STATUS_COLLECTION", "registered");
    try {
      properties.forEach(async (property) => {
        let reservationsQuery = await firebase
          .firestore()
          .collection("reservations")
          .where("propertyId", "==", property.id)
          .where("registered", "!=", null)
          .where("checkedIn", "==", null)
          .where("canceled", "==", null)
          .get();

        reservationsQuery.docs.forEach(async (reservation) => {
          let docObj = {
            id: reservation.id,
            // snap: reservation,
            ...reservation.data()
          };
          if (docObj.primaryGuest) {
            let guest = await firebase
              .firestore()
              .collection("guests")
              .doc(docObj.primaryGuest)
              .get();
            docObj.primaryGuest = {
              id: guest.id,
              ...guest.data()
            };
          }
          commit("ADD_ITEM_TO_STATUS_COLLECTION", {
            docObj,
            status: "registered"
          });
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  async getCheckedInReservations({ commit }, properties) {
    commit("CLEAR_STATUS_COLLECTION", "current");
    try {
      properties.forEach(async (property) => {
        let reservationsQuery = await firebase
          .firestore()
          .collection("reservations")
          .where("propertyId", "==", property.id)
          .where("checkedIn", "!=", null)
          .where("canceled", "==", null)
          .get();

        reservationsQuery.docs.forEach(async (reservation) => {
          let docObj = {
            id: reservation.id,
            // snap: reservation,
            ...reservation.data()
          };
          if (docObj.primaryGuest) {
            let guest = await firebase
              .firestore()
              .collection("guests")
              .doc(docObj.primaryGuest)
              .get();
            docObj.primaryGuest = {
              id: guest.id,
              ...guest.data()
            };
          }
          commit("ADD_ITEM_TO_STATUS_COLLECTION", {
            docObj,
            status: "current"
          });
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  },

  async getCanceledReservations({ commit }, properties) {
    commit("CLEAR_STATUS_COLLECTION", "canceled");
    try {
      properties.forEach(async (property) => {
        let reservationsQuery = await firebase
          .firestore()
          .collection("reservations")
          .where("propertyId", "==", property.id)
          .where("canceled", "!=", null)
          .get();

        reservationsQuery.docs.forEach(async (reservation) => {
          let docObj = {
            id: reservation.id,
            // snap: reservation,
            ...reservation.data()
          };
          if (docObj.primaryGuest) {
            let guest = await firebase
              .firestore()
              .collection("guests")
              .doc(docObj.primaryGuest)
              .get();
            docObj.primaryGuest = {
              id: guest.id,
              ...guest.data()
            };
          }
          commit("ADD_ITEM_TO_STATUS_COLLECTION", {
            docObj,
            status: "canceled"
          });
        });
      });
    } catch (error) {
      console.log("error", error);
    }
  },
  async getItem({ commit }, reservationId) {
    try {
      // Get Reservation
      let reservation = await firebase
        .firestore()
        .collection("reservations")
        .doc(reservationId)
        .get();

      commit("SET_ITEM", {
        id: reservationId,
        ...reservation.data()
      });
      return Promise.resolve();
    } catch (error) {
      console.log("error", error);
    }
  },
  async cancelReservation({ commit }, reservationId) {
    try {
      let now = Math.floor(new Date().getTime() / 1000);
      await firebase
        .firestore()
        .collection("reservations")
        .doc(reservationId)
        .update({
          canceled: now
        });

      let passesQuery = await firebase
        .firestore()
        .collection("passes")
        .where("reservationId", "==", reservationId);

      passesQuery.forEach(async (pass) => {
        await pass.delete();
      });

      commit("UPDATE_ITEM", { key: "canceled", value: now });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  async restoreReservation({ commit }, reservationId) {
    try {
      await firebase
        .firestore()
        .collection("reservations")
        .doc(reservationId)
        .update({
          canceled: null
        });

      commit("UPDATE_ITEM", { key: "canceled", value: null });
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  },
  async deleteReservation({ commit }, reservationId) {
    try {
      await firebase
        .firestore()
        .collection("reservations")
        .doc(reservationId)
        .delete();

      let guestsQuery = await firebase
        .firestore()
        .collection("guests")
        .where("reservationId", "==", reservationId);

      guestsQuery.forEach(async (guest) => {
        await guest.delete();
      });

      commit("CLEAR_ITEM");
      return Promise.resolve();
    } catch (error) {
      return Promise.reject();
    }
  }
};

const getters = {
  getUpcoming: (state) => {
    return state.upcoming;
  },
  getCurrent: (state) => {
    return state.current;
  }
};
export const reservation = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};

<template>
  <div class="mt-5">
    <ais-instant-search :search-client="searchClient" index-name="properties">
      <div class="notification">
        <ais-search-box
          placeholder="Search guests, reservations, visitors, license plates, properties, and more"
        />
      </div>
      <ais-hits :class-names="{ 'ais-Hits-item': 'is-block' }">
        <template v-slot:item="{ item }">
          <div class="level is-mobile">
            <div class="level-left">
              <div class="level-item">
                <div>
                  <p class="heading">Property</p>
                  <p class="title">
                    {{ item.houseNumber }} {{ item.streetName }}
                  </p>
                </div>
              </div>
            </div>
            <div class="level-right">
              <div class="level-item">
                <router-link
                  class="button"
                  :to="{
                    name: 'ViewProperty',
                    params: { id: item.objectID }
                  }"
                  >View Property</router-link
                >
              </div>
            </div>
          </div>
        </template>
      </ais-hits>
      <ais-index index-name="guests">
        <ais-hits :class-names="{ 'ais-Hits-item': 'is-block' }">
          <template v-slot:item="{ item }">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <div>
                    <p class="heading">Guest</p>
                    <p class="title">
                      {{ item.nameFirst }} {{ item.nameLast }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <router-link
                    class="button"
                    :to="{
                      name: 'ViewReservation',
                      params: { id: item.reservationId }
                    }"
                    >View Reservation</router-link
                  >
                </div>
              </div>
            </div>
          </template>
        </ais-hits>
      </ais-index>
      <ais-index index-name="visitors">
        <ais-hits :class-names="{ 'ais-Hits-item': 'is-block' }">
          <template v-slot:item="{ item }">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <div>
                    <p class="heading">Visitor</p>
                    <p class="title">
                      {{ item.nameFirst }} {{ item.nameLast }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <div>
                    <router-link
                      class="button"
                      :to="{
                        name: 'ViewProperty',
                        params: { id: item.propertyId }
                      }"
                      >View Property</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ais-hits>
      </ais-index>
      <ais-index index-name="vendors">
        <ais-hits :class-names="{ 'ais-Hits-item': 'is-block' }">
          <template v-slot:item="{ item }">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <div>
                    <p class="heading">Company</p>
                    <p class="title">{{ item.companyName }}</p>
                  </div>
                </div>
                <div class="level-item">
                  <div>
                    <p class="heading">Vendor Name</p>
                    <p class="title">
                      {{ item.nameFirst }} {{ item.nameLast }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <div>
                    <router-link
                      class="button"
                      :to="{
                        name: 'ViewProperty',
                        params: { id: item.propertyId }
                      }"
                      >View Property</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ais-hits>
      </ais-index>
      <ais-index index-name="reservations">
        <ais-hits :class-names="{ 'ais-Hits-item': 'is-block' }">
          <template v-slot:item="{ item }">
            <div class="level is-mobile">
              <div class="level-left">
                <div class="level-item">
                  <div>
                    <p class="heading">Reservation</p>
                    <p class="title">
                      {{ item.dateArrivalUnix | moment("MMM Do YYYY") }} to
                      {{ item.dateDepartureUnix | moment("MMM Do YYYY") }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="level-right">
                <div class="level-item">
                  <div>
                    <router-link
                      class="button"
                      :to="{
                        name: 'ViewReservation',
                        params: { id: item.objectID }
                      }"
                      >View Reservation</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
          </template>
        </ais-hits>
      </ais-index>
    </ais-instant-search>
  </div>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
import "instantsearch.css/themes/satellite-min.css";

export default {
  data() {
    return {
      searchClient: algoliasearch(
        "B2YIJT0BWG",
        "ef6d13a7eb66f7072955ef5e14cbb150"
      )
    };
  }
};
</script>

<style lang="scss" scoped>
.notification {
  background-color: #e3e3e3;
  // color: #fff;
}
</style>
<style lang="scss">
// .ais-SearchBox-input {
//   // padding-left: calc(0.75em - 1px);
//   box-shadow: none;
//   // background-color: white;
//   // border-color: #dbdbdb;
//   border-radius: 4px;
//   color: #363636;
//   font-size: 1rem;
//   height: 2.5rem;
//   line-height: 1.5;
//   ::placeholder {
//     color: #363636;
//   }
// }
// .ais-Hits-list {
//   display: flex;
//   flex-wrap: wrap;
// }
// .ais-Hits-item {
//   position: relative;
//   width: 24%;
//   margin: 10px 5px;
//   display: flex;
//   flex-direction: column;
//   align-items: flex-end;
//   .button {
//     margin-top: 10px;
//   }
//   .tag {
//     position: absolute;
//     top: 0;
//     left: 0;
//   }
// }
</style>

import firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/database";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";

// import 'firebase/firestore'
let config = {
  apiKey: "AIzaSyDU3Q3Nl1uidx7gsKlAooDa6M-taTLqlKo",
  authDomain: "gateaccess-29471.firebaseapp.com",
  projectId: "gateaccess-29471",
  storageBucket: "gateaccess-29471.appspot.com",
  messagingSenderId: "222620950601",
  appId: "1:222620950601:web:92203ebef9cbae7c7c5f98",
  measurementId: "G-18H3FJPJ7Y"
};
console.log("NODE ENVIRONMENT", process.env.NODE_ENV);
console.log("FIREBASE CONFIG", config);

firebase.initializeApp(config);

// // Initialize Firebase
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// firebase utils
const database = firebase.database();
const firestore = firebase.firestore();
const functions = firebase.functions();
const auth = firebase.auth();
const currentUser = firebase.auth().currentUser;
const storage = firebase.storage();

export {
  firebase,
  database,
  functions,
  firestore,
  auth,
  storage,
  currentUser,
  config
};

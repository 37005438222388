<template>
  <div class="card">
    <div class="card-header">
      <div class="card-header-title">
        {{ property.houseNumber }} {{ property.streetName }}
      </div>
    </div>
    <div class="card-content">
      <span class="is-capitalized">
        {{ property.role }}
      </span>
    </div>
    <div class="card-footer">
      <router-link
        class="card-footer-item"
        :to="{ name: 'ViewProperty', params: { id: property.id } }"
        >View</router-link
      >
    </div>
  </div>
</template>

<script>
export default {
  props: ["property"]
};
</script>

<style lang="scss" scoped></style>
